
/* eslint-disable */

import { ref, reactive, onMounted, onBeforeMount, defineExpose } from "vue";
import { useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import CloudFun, {
  Condition,
  Operator,
  Sorting,
  defineComponent,
} from "@cloudfun/core";
import { OrderQueryData } from "@/views/order/types";

import Swal from "sweetalert2";
import FullCalendar, { Calendar, EventApi } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

import DetailModal from "../../order/main/Detail.vue";
import NextTimeAppointmentModal from "./calendar-modal/NextTimeAppointmentModal.vue";

export default defineComponent({
  components: {
    NextTimeAppointmentModal,
    DetailModal,
    FullCalendar,
  },
  setup() {
    const model = CloudFun.current?.model,
      $t = CloudFun.current?.i18n.global.t,
      application = CloudFun.current,
      router = useRouter();
    const queryData = reactive<OrderQueryData>({
      store: "",
      employee: "",
      startDate: "",
      endDate: "",
    });

    const today = ref<string>("");
    // Calendar reference
    const calendarRef = ref<Calendar>();
    const calendarApi = ref();
    const dataLoaded = ref(true);

    const selectedStylist: any = ref(null),
      store = reactive({
        Id: "",
        Name: "",
        Phone: "",
        Email: "",
        ContactPerson: "",
        BookingStartTime: "",
        BookingEndTime: "",
        BookingUnitTime: "",
        BookingIntervalTime: "",
        Address: {
          Line: "",
        },
        Week: [],
        RecessPeriod: { Durations: [] },
        BookingStartTimeDP: "",
        BookingEndTimeDP: "",
      }),
      stylists: any = ref<{ label: string; value: number; id: string }[]>([]);
    let currentDate: any = "",
      stylistIds = null,
      // storeId = this.$store.getters["auth/defaultStoreId"],
      // stylists =[{ Value =-1, Name ="全部", hide =false }],
      // selectedStylist =-1,
      reserveVisible = ref(false),
      isReservable = true;

    const allResources: any = ref([]),
      recessDurationEvents: any = [],
      reservationInfo: any = ref({});

    const appointmentModal = reactive({
        title: "預約內容",
        visible: false,
        data: {},
      }),
      detailModal = reactive({
        visible: false,
        orderId: "",
        show(orderId) {
          console.log("Detail", orderId);
          detailModal.orderId = orderId;
          detailModal.visible = true;
        },
      }),
      employeePerformanceModal = reactive({
        title: "設計師業績",
        visible: false,
        data: {},
      }),
      insertCustomerModal = reactive({
        title: "預約內容",
        visible: false,
        data: {},
      }),
      leaveTakenModal = reactive({
        title: "休假內容",
        visible: false,
        data: {},
      }),
      monthlyViewModal = reactive({
        title: "設計師個人月行事曆",
        visible: false,
        data: {},
      }),
      selectedTime: any = ref(""),
      selectedEmployee: any = reactive({});

    const calendarOptions: any = reactive({
      refetchResourcesOnNavigate: false,
      eventDurationEditable: true,
      dayMaxEvents: 3,
      dayMaxEventRows: 3,
      allDaySlot: false,
      customButtons: {
        dateButton: {
          text: "日曆",
          // icon: 'icon-calendar',
        },
      },
      titleFormat: {
        // will produce something like "Tuesday, September 18, 2018"
        month: "long",
        year: "numeric",
        day: "numeric",
        weekday: "narrow",
      },
      plugins: [
        dayGridPlugin,
        interactionPlugin,
        timeGridPlugin,
        resourceTimeGridPlugin,
        resourceTimelinePlugin,
      ],
      resourceAreaHeaderContent: $t?.("label.stylist"),
      resourceAreaWidth: 100,
      headerToolbar: {
        left: "",
        center: "title",
        right:
          "resourceTimeGridDay,resourceTimeGridWeek,dayGridMonth,prev,next",
      },
      buttonText: {
        today: "今日",
        week: "週",
        day: "日",
        month: "月",
      },
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: false,
        hour12: false,
      },
      locale: "zh-tw",
      initialView: "resourceTimeGridWeek",
      events: [],
      resources: [],
      resourceOrder: "index,order",
      slotDuration: "00:15:00",
      slotMinTime: "09:00",
      slotMaxTime: "23:00",
      businessHours: [],
      selectable: true,
      // selectConstraint: "businessHours",
      // slotMinWidth: 40,
      // contentHeight: 600,
      height: "auto",
      droppable: true,
      schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
      eventContent: function (arg) {
        // console.log("eventContent:", arg.event);
        if (arg.event) {
          let event = arg.event;
          var icon = event.extendedProps.icon,
            // start = self.$cf.dateExt.formatDate(event.start, "HH:mm"),
            title = event.title,
            isNew = event.extendedProps.isNew,
            content = event.extendedProps.content
              ? event.extendedProps.content
              : "",
            eventHTML = `<i class="mr-1 ${icon}"></i>${title}<br>${content}`;
          if (isNew) {
            eventHTML = '<mark class="newCustomer">N</mark>' + eventHTML;
          }
          return {
            html: eventHTML,
          };
        }
      },
      eventClassNames: function (arg) {
        return arg.event.extendedProps.class;
      },
      // viewClassNames: function (view, el) {
      //   console.log("viewChange:", view);
      // },
      datesSet: function (info) {
        console.log("Dates set", info);
        // if (!monthMode) {
        if (currentDate !== info.startStr) {
          // console.log("Date change", info);

          currentDate = info.startStr;
          queryData.startDate = $h.formatDate(
            new Date(info.startStr),
            "yyyy/MM/dd"
          );
          queryData.endDate = $h.formatDate(
            new Date(info.endStr),
            "yyyy/MM/dd"
          );
          refreshCal();
        } else {
          return;
        }
        // }
      },
      select: function (info) {
        console.log("Selected!", info);
        // if (!self.$user.isApprove(systemPermission.OrderCreate)) {
        //   self.$swal({ title: "錯誤", text: "無開單權限", icon: "error" });
        //   return;
        // }
        if (isReservable === true) {
          selectedTime.value = info.startStr;
          // model?.dispatch("stylist/find", info.resource.id).then((resp) => {
          // console.log("Employee match", resp);
          selectedEmployee.value = {
            EmployeeId: info.resource.id,
            Nickname: info.resource.title,
          };
          onAppointment(false, 0);
          // });
        }
      },
      dateClick: function (dateinfo) {
        console.log(dateinfo);
        if (dateinfo.view.type === "dayGridMonth") {
          calendarApi.value.changeView("resourceTimeGridDay", dateinfo.date);
          return;
        }
        selectedTime.value = $h.formatDate(dateinfo.date, "yyyy-MM-dd HH:mm");
        // self.selectedTime = dateinfo.date;
      },
      dayCellWillUnmount: function (arg) {
        // console.log('datcell', arg);
        if (arg.view.type !== "dayGridMonth") {
          arg.el.style.backgroundColor = arg.resource.extendedProps.color;
        }
      },
      resourceLabelDidMount: function (arg) {
        console.log("resource", arg.resource);
      },
      resourceLaneDidMount: function (arg) {
        arg.el.style.backgroundColor = arg.resource.extendedProps.color;
      },
      eventClick: function (info) {
        console.log("Event Clicked!", info.event.extendedProps);
        var eventId = info.event.extendedProps.eventId;
        // if (
        //   info.event.extendedProps.eventType !== 0 &&
        //   info.event._def.title !== "休息時段"
        // ) {

        //   return;
        // } else if (info.event._def.title == "休息時段") {
        //   // if (allowForceCreate) {
        //   // }
        //   return;
        // } else {
        var orderId = info.event.extendedProps.orderId;
        orderId = orderId;
        detailModal.show(orderId);
        // }
      },
      eventMouseLeave: function (info) {
        isReservable = true;
      },
    });

    onBeforeMount(() => {
      queryData.store = model?.user.storeId;

      Promise.all([
        model?.dispatch("store/find", queryData.store),
        loadStylists(),
      ]).then(([data]) => {
        Object.assign(store, data);
        if (store.BookingStartTime)
          store.BookingStartTimeDP = store.BookingStartTime.split("T")[1];
        if (store.BookingEndTime)
          store.BookingEndTimeDP = store.BookingEndTime.split("T")[1];
        console.log("Store:", data);
        store.Week = data.RecessPeriod.Durations;
        //console.log(store)
        if (
          store.BookingIntervalTime &&
          store.BookingIntervalTime !== "00:00:00"
        )
          calendarOptions.slotDuration = store.BookingIntervalTime;

        const startTime = $h.formatDate(
            store.BookingStartTime.substring(0, 19),
            "HH:mm"
          ),
          endTime = $h.formatDate(
            store.BookingEndTime.substring(0, 19),
            "HH:mm"
          );
        // Set Bussiness Hours
        calendarOptions.slotMinTime = startTime;
        calendarOptions.slotMaxTime = endTime;
        calendarOptions.businessHours = {
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          startTime: startTime,
          endTime: endTime,
        };
        if (store.RecessPeriod?.Durations.length > 0) {
          store.RecessPeriod?.Durations.filter((e: any, index) => {
            console.log("Event:", e);
            if (e !== null) {
              recessDurationEvents.push({
                title: "休息時段",
                daysOfWeek: [e.DayOfWeek],
                startTime: e.StartTime,
                class: "text-vertical-center",
                icon: "iconsminds-reload",
                endTime: e.EndTime,
                display: "background",
                content: "",
                eventType: 8,
                color: "rgba(90, 90, 90, 0.3)",
              });
            }
          });
          console.log("recessDurationEvents:", recessDurationEvents);
        }
      });
    });

    onMounted(() => {
      if (!CloudFun.user.Id) {
        router?.push("/login");
      }
      if (calendarRef.value) {
        console.log("calendarRef:", calendarRef.value);
        // @ts-ignore
        calendarApi.value = calendarRef.value.getApi();
      }
      today.value = getTodayDate();
      queryData.startDate = today.value;
      queryData.endDate = today.value + " 23:59:59";
      refreshCal();
    });

    const getTodayDate = () => {
      var fullDate = new Date();
      var yyyy = fullDate.getFullYear();
      var MM =
        fullDate.getMonth() + 1 >= 10
          ? fullDate.getMonth() + 1
          : "0" + (fullDate.getMonth() + 1);
      var dd =
        fullDate.getDate() < 10 ? "0" + fullDate.getDate() : fullDate.getDate();
      var today = yyyy + "/" + MM + "/" + dd;
      return today;
    };

    // 取得店內的設計師名單顯示於行事曆上
    const loadStylists = () => {
      const condition = new Condition();
      condition.and("Employments.StoreId", Operator.Equal, model?.user.storeId);
      condition.and("Employee.ShowInCalendar", Operator.Equal, true);
      model
        ?.dispatch("stylist/query", {
          page: 1,
          pageSize: 30,
          sortings: [new Sorting("Ordinal"), new Sorting("Employee.DataMode")],
          condition: condition,
        })
        .then((resp) => {
          var stylistData = resp.data;
          var listData: any = [];
          console.log("stylistData:", stylistData);
          allResources.value = stylistData.map((ele, index) => {
            listData.push({
              value: index,
              id: ele.Id,
              empId: ele.EmployeeId,
              label: ele.Employee?.Person.Nickname,
            });
            return {
              id: ele.EmployeeId,
              dataId: ele.Id,
              title: ele.Employee?.Person.Nickname,
              color: ele.Employee?.Color,
              hide: false,
              // order: ele.Ordinal,
              index: index,
            };
          });

          stylists.value = listData;
          // calendarOptions.resources = new Array(allResources.value[0]);
          // selectedStylist.value = stylists.value[0].value;
          // queryData.employee = stylists.value[0].id;
          reservationInfo.value = model?.getters["reservationState/data"];
          var currentStylist =
            stylists.value.find((ele) => {
              if (ele.empId === CloudFun.current?.user.Id) return ele;
            }) || null;
          if (reservationInfo.value.EmployeeId) {
            const rs = stylists.value.find((ele) => {
              if (ele.empId == reservationInfo.value.EmployeeId) return ele;
            });
            setStylist(rs);
          } else if (currentStylist) {
            setStylist(currentStylist);
          } else {
            setStylist(stylists.value[0]);
          }
        });
    };

    const setStylist = (e) => {
      console.log(e.value);
      calendarOptions.resources = new Array(allResources.value[e.value]);
      selectedStylist.value = e.value;
      queryData.employee = stylists.value[e.value].empId;
      refreshCal();
    };

    // refresh calendar events
    const refreshCal = () => {
      const calendarEle = calendarOptions,
        condition = new Condition();
      if (queryData.employee)
        condition.and("EmployeeId", Operator.Equal, queryData.employee);
      if (queryData.startDate)
        condition.and(
          "StartTime",
          Operator.GreaterThanOrEqual,
          queryData.startDate
        );
      if (queryData.endDate)
        condition.and("EndTime", Operator.LessThanOrEqual, queryData.endDate);
      Promise.all([
        model?.dispatch("employeeEvent/query", {
          page: 1,
          pageSize: 100,
          condition: condition,
          sortings: { column: "CreatedTime", order: 1 },
        }),
      ])
        .then(([value]) => {
          let reservations = value.data;

          console.log("reservations", reservations);
          reservations = reservations.map((e, index) => {
            if (e.Type === 0) {
              let event = JSON.parse(e.SerializeContent.Content);
              return {
                // eventId: e.Id,
                start: event.StartTime,
                end: event.EndTime,
                title: event.Customer.Name,
                isNew: event.Customer.IsNew,
                stylist: event.Name,
                sex: event.Customer.Gender || "",
                icon: "order-event" + event.Customer.Gender ? "man" : "woman",
                content: event.Description,
                class: [
                  event.Status === 1
                    ? "status-approved"
                    : event.Status === 3
                    ? "status-inprogress"
                    : event.Status === 31
                    ? "status-checkedOut"
                    : "",
                  event.EstimatedTime ? "has-estimated" : "",
                ],
                backgroundColor: event.Customer.GradeColor
                  ? event.Customer.GradeColor
                  : "",
                resourceId: event.EmployeeId,
                eventType: event.EventType,
                stylistId: event.Id,
                employeeId: event.EmployeeId,
                customerId: event.CustomerId,
                orderId: e.OrderId,
                editable: event.Status !== 31 ? true : false,
                resourceEditable: event.Status !== 31 ? true : false,
              };
              /* Official holiday */
            } else {
              return {
                start: e.StartTime,
                end: e.EndTime,
                title: e.Description ?? "休假",
                stylist: "",
                icon: "iconsminds-close",
                // content: e.Description,
                class: "status-notAllowed",
                display: "background",
                style: "",
                resourceId: e.EmployeeId,
                EventType: e.EventType,
                eventId: e.EventId,
                orderId: e.OrderId,
                editable: e.Status !== 31 ? true : false,
                resourceEditable: e.Status !== 31 ? true : false,
              };
            }
          });
          calendarApi.value.setOption(
            "events",
            reservations.concat(recessDurationEvents)
          );
        })
        .catch((failure) => {
          CloudFun.send("error", {
            subject: $t?.("message.operate-fail"),
            content: failure,
          });
          dataLoaded.value = false;
        });
    };

    const dateChanged = (date) => {
      console.log("Date changed:", date);
      // var calendarApi = calendarApi.value;
      const newDate = date;
      calendarApi.value.gotoDate(newDate);
      // queryData.startDate = newDate
      // queryData.endDate = newDate + ' 23:59:59'
      // refreshCal();
    };

    const onAppointment = (isNewCustomer, eventType) => {
      const reserveObject = {
        StartTime: $h.formatDate(
          new Date(selectedTime.value),
          "yyyy/MM/dd HH:mm"
        ),
        Employee: selectedEmployee.value.Nickname,
        EmployeeId: selectedEmployee.value.EmployeeId,
        BirthDate: "",
        Customer: null,
        CustomerId: null,
        Type: eventType || 0,
        IsNew: isNewCustomer || false,
        Partition: "未指定",
      };
      // 再次預約的顧客資料
      if (reservationInfo.value.Customer) {
        reserveObject.Customer = reservationInfo.value.Customer;
        reserveObject.CustomerId = reservationInfo.value.CustomerId;
        reserveObject.Type = 0;
      }

      appointmentModal.visible = true;
      appointmentModal.data = reserveObject;
    };

    return {
      currentDate,
      calendarRef,
      calendarOptions,
      dateChanged,
      appointmentModal,
      detailModal,
      setStylist,
      refreshCal,
      dataLoaded,
      stylists,
      selectedStylist,
      reservationInfo,
    };
  },
});
